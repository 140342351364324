const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://www.danyalkhan.me',
  title: 'DK.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Danyal Khan',
  role: 'Third Year Software Engineering Student',
  description: `Hi! I'm a third-year Software Engineering student at the University of Waterloo. I love to learn and build new stuff. My tech interests include full stack web development, enterprise software & cyber security. Besides learning about technology, I love to run and cook. I enjoy reading classic novels and am a huge productivity geek!`,
  resume: 'https://danyal002.github.io/Resume/resume_cv.pdf',
  social: {
    linkedin: 'https://www.linkedin.com/in/danyalkhan02/',
    github: 'https://github.com/danyal002',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'CmdStack',
    description:
      'Created a cross‐platform command line app that allows developers to maintain an organized & searchable knowledge bank of commands & notes with the aim of replacing Notepad',
    stack: ['Rust', 'TypeScript', 'MantineUI'],
    sourceCode: 'https://github.com/danyal002/cmd-stack',
  },
  {
    name: 'AI Facial Recognition Doorlock',
    description:
      'Programmed an Arduino to fully rotate a deadbolt lock using facial recognition in the aim of replacing physical keys. Implemented facial recognition using OpenCV',
    stack: ['C', 'Arduino', 'Flask', 'OpenCV'],
    sourceCode: 'https://github.com/OrenLeung/FacialRecognitionDoorLock',
  },
  // {
  //   name: 'Library Mangement System',
  //   description:
  //     'Created a solution for managing libraries using Java SE framework & MySQL to replace manual record keeping of books.',
  //   stack: ['Java SE', 'Material UI', 'MySQL'],
  //   sourceCode: 'https://github.com/danyal002/Library-Management-System',
  // },
  {
    name: 'Stock Volatility Tracker',
    description:
      'A Python application that retrieves data from the AlphaVantage API using stock ticker symbols. It checks stock prices hourly and sends alerts via a Discord bot if the stock price has increased/decreased a programmed threshold.',
    stack: ['Python', 'Pandas', 'Discord Library'],
    sourceCode: 'https://github.com/danyal002/stockAlert',
  },
]

const skills = [
  'C#',
  'Golang',
  'Rust',
  'Java',
  'TypeScript',
  'C++',
  'HTML',
  'CSS',
  '.NET Core',
  'k8s',
  'React',
  'Bootstrap',
  'jQuery',
  'T-SQL',
  'Redis',
  'PostgreSQL',
  'MongoDB',
  'Azure DevOps',
  'AWS',
  'Prometheus',
  'Jaegar',
  'Grafana',
  'Docker',
  'Gitlab Runners',
  'Git',
  'Jira',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'd27khan@uwaterloo.ca',
}

export { header, about, projects, skills, contact }
